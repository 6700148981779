import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import ArticlePreview from '../components/article-preview';
import { AdUnit } from '../components/adunit';
import { NoResults } from '../components/no-results';
import { MobileResults } from '../components/mobile-results';
import filterUnapprovedEntries from '../filter-unapproved-entries';
import fixAssetUrl from '../fix-asset-url';
import Filters from './filters';
import buildCategoryPagePath from '../build-category-page-path';
import useMobile from '../hooks/use-mobile';
import LOCATIONS from '../locations';

export default ({ data, pageContext }) => {
  const isFilteredPage = pageContext.subcategory ? true : false;
  const { category } = data;
  const { subcategories } = pageContext;
  const ads = data.ads.articlesAdUnits || {};
  const articles = filterUnapprovedEntries(
    data.articles.edges.map(({ node }) => node)
  );

  let categoryImage = category.featuredImage?.file?.url;
  categoryImage = categoryImage ? fixAssetUrl(categoryImage) : null;

  let featuredArticles = category.featuredArticles;

  const buildPath = buildCategoryPagePath;
  let numResults = articles.length;
  const resultsFor = `${numResults} article${numResults === 1 ? '' : 's'}`;
  let subcategory = pageContext.subcategory;
  const hasArticles = true;
  const resultsProps = {
    section: 'articles',
    num: numResults,
    subcategory: LOCATIONS[subcategory]?.label,
  };

  const isMobile = useMobile();
  const [filterOpen, setFilterOpen] = useState(false);

  const handleFilterToggle = () => {
    setFilterOpen(!filterOpen);
  };

  useEffect(() => {
    filterOpen
      ? document.body.classList.add('hideScroll')
      : document.body.classList.remove('hideScroll');

    document.querySelector('header').classList.toggle('fixed', isMobile);
  }, [filterOpen, isMobile]);

  return (
    <Layout
      title={category.title}
      currentSection={category.slug}
      mainClass="section-landing"
      image={categoryImage}
    >
      <div className={`section-header ${filterOpen ? 'removeShadow' : ''}`}>
        <div className="section-header-inner">
          <h1 className="section-title">{category.title}</h1>
        </div>
        {isMobile && !filterOpen && (
          <div className="section-filtering">
            <div className="mobile-results">
              {subcategory ? (
                <MobileResults {...resultsProps} />
              ) : (
                <p>{resultsFor}</p>
              )}
            </div>
            <button
              className="btn mobile-filter-btn"
              data-heading-filters
              onClick={() => handleFilterToggle()}
            >
              <span>Filters</span>
              <span className="material-icons">filter_list</span>
            </button>
          </div>
        )}
      </div>
      <div className="article-page">
        {category.slug !== 'about-us' && (
          <div className="filters-wrap">
            <div
              className={`filters ${isMobile ? 'mobile' : ''} ${
                filterOpen ? '' : 'hide'
              } ${ads?.length ? '' : 'no-border'}`}
            >
              {/* <Filters
                {...{
                  category,
                  subcategory,
                  buildPath,
                  numResults,
                  hasArticles,
                  subcategories,
                  isMobile,
                  handleFilterToggle,
                }}
              /> */}
            </div>
            {featuredArticles && (
              <div className="category container">
                <div className="featured-articles-list-wrap">
                  <h1 className="section-title">
                    {category.featuredArticlesTitle}
                  </h1>
                  <ul className={` featured-articles-list`}>
                    {featuredArticles.map(article => (
                      <li key={article.id}>
                        <ArticlePreview article={article} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}

        <ul
          className={`article article--articles ${
            isFilteredPage && 'articles--filtered'
          } ${ads?.length ? '' : 'no-border'}`}
        >
          {!articles.length && (
            <NoResults
              message={`There are no events for "${
                LOCATIONS[subcategory]?.label || 'All locations'
              }".`}
            />
          )}
          {isFilteredPage ? (
            <>
              {articles.map(article => (
                <li key={article.id}>
                  <ArticlePreview article={article} />
                </li>
              ))}
            </>
          ) : (
            <>
              <li>
                <ArticlePreview article={articles[0]} isHero={true} />
              </li>
              {articles
                .filter(article => article.id !== articles[0].id)
                .map(article => (
                  <li key={article.id}>
                    <ArticlePreview article={article} />
                  </li>
                ))}
            </>
          )}
        </ul>
        <div className="sidebar">
          <div className="sidebar-inner">
            {ads && ads.map(ad => <AdUnit key={ad.id} ad={ad} />)}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query SectionBySlug($slug: String!, $subcategory: String) {
    category: contentfulCategory(slug: { eq: $slug }) {
      title
      slug
      showAdOnPage
      heroAdUnit {
        asset {
          title
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
        link
      }
      featuredImage {
        file {
          url
        }
      }
      featuredArticlesTitle
      featuredArticles {
        ...ArticleListFragment
        heroImage {
          fluid(maxWidth: 307, maxHeight: 183, resizingBehavior: FILL) {
            src
          }
        }
      }
    }

    articles: allContentfulArticle(
      filter: {
        categories: { elemMatch: { slug: { eq: $slug } } }
        subcategories: { elemMatch: { slug: { eq: $subcategory } } }
      }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          publishDate(formatString: "MMMM D, YYYY")
          heroImage {
            title
            fluid(maxWidth: 1280, maxHeight: 717, resizingBehavior: FILL) {
              src
            }
          }
          ...ArticleListFragment
        }
      }
    }

    ads: contentfulHomePage {
      ...ArticlesAdsFragment
    }
  }
`;
