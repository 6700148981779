module.exports = {
  'arts-culture': {
    label: 'Arts+Culture',
  },
  'eat-drink-shop': {
    label: 'Eat+Drink+Shop',
  },
  outdoors: {
    label: 'Outdoors',
  },
};
